@import "../common/colours"
@import "../common/font-sizes"

.required
  color: $red1
.page-title
  font-size: $f1
  font-weight: bold
.form-list
  .title
    font-size: $f5
  .description
    font-size: $f7
  .completed-container
    margin-bottom: 5px
    .completed
      color: $green2
      border: 2px solid $green2
      padding: 5px
      font-size: $f7

.rendered-form
  padding-bottom: 20px
  .heading
    padding-bottom: 10px
    margin-bottom: 10px
    border-bottom: 2px solid $blue1
    .title
      font-size: $f1
      font-weight: bold
      margin-bottom: 10px
    .description
      font-size: $f5
    .close-button
      float: right
  .form-element
    padding: 10px 0
    input[type="radio"]
      -webkit-appearance: radio
      height: 30px
      width: 30px
      margin-right: 10px
    input[type="checkbox"]
      -webkit-appearance: checkbox
      height: 30px
      width: 30px
      margin-right: 10px
    .radio-element, .checkbox-element
      display: flex
      align-items: center
      margin: 5px
      label
        font-weight: normal
    select
      height: 30px
      padding: 0 20px
      margin: 5px 10px
    label
      font-size: $f6
      font-weight: bold
    textarea
      width: 100%
      min-height: 100px
      margin: 5px 10px
    .help
      border-bottom: 1px solid $blue1
      background: $white
    .description
      padding: 5px 0
    input[type="text"]
      width: 100%
      min-height: 30px
      margin: 5px 10px
      border: 1px solid $black
    .required-banner
      padding: 5px
      margin: 5px 0
      font-size: $f6
      font-weight: bold
      background: $red1
      color: $white
    .warning
      color: $red1
    .form-submit
      width: 100%
      .spinner-border
        height: 20px
        width: 20px
        float: right


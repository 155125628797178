@import "../common/colours"
@import "../common/font-sizes"

.navbar.navbar-light
  padding: 10px
  box-shadow: 0 1px 5px rgba(0,0,0,.160784)
  background: $white
  min-height: 70px
  font-size: $f5
  .navbar-brand
    transform: translateX(-50%)
    left: 50%
    position: absolute
    top: 0
    .logo
      max-height: 50px
      max-width: 300px
  .nav-link
    color: $black
    text-decoration: none
  .logout-button
    position: absolute
    right: 0
    top: 5px


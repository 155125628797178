$red1: #b10e1e
$red2: #7f0e1a
$white: #ffffff
$black: #4A4A4A

$blue1: #0C6EB4
$blue2: #0c5084
$green1: #509526
$green2: #38711f
$orange1: #D27A03
$orange2: #a86202
$grey1: #C4C4C4
$grey2: #9B9B9B
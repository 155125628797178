@import "../common/colours"
@import "../common/font-sizes"

.change-data-panel
  border-radius: 0.25rem
  border: 1px solid $grey2
  .title
    text-align: center
    padding-top: 8px
    background: $grey1
    font-size: $f6
  .val-col
    .row
      padding: 3px
      .value
        text-align: center
        padding: 5px
        font-size: $f6
      .change-button
        text-align: center
        padding: 5px
        border-radius: 0.25rem
        cursor: pointer



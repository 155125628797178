@import "../common/colours"
@import "../common/font-sizes"

.login-page
  min-height: calc(100% - 70px)
  display: flex
  justify-content: center
  align-items: center
  background: $grey2

  .login-panel
    background-size: cover
    width: 450px
    padding: 20px
    margin: 20px 0
    overflow: hidden
    box-shadow: 0 10px 10px rgba(0,0,0,.160784)
    position: relative
    background: $white

    .login-form
      label, input, button
        width: 100%
      input
        border: 1px solid $black
        padding: 10px
      button
        margin-top: 10px
      .input-group
        margin: 10px 0


@import "colours"

.spinner-overlay
  top: 0
  left: 0
  height: 100%
  width: 100%
  position: fixed
  background: $grey1
  opacity: 0.8
  z-index: 100
  .content
    height: 100px
    width: 200px
    text-align: center
    position: fixed
    top: calc(50% - 50px)
    left: calc(50% - 100px)
@import "./common/font-sizes"
@import "./common/colours"
@import "~bootstrap/scss/bootstrap.scss"

@font-face
  font-family: 'Archivo'
  src: local('Archivo'), url(./fonts/Archivo-Regular.ttf) format('truetype')

@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype')

@font-face
  font-family: 'Eras Bold ITC'
  src: local('Eras Bold ITC'), url(./fonts/ErasBoldITC.ttf) format('truetype')

body
  font-family: Archivo
  font-size: $f7

button
  cursor: pointer

html, body, #root
  height: 100%

.hidden
  display: none

.green-button
  background: $green1
  color: $white
  font-size: $f6
  padding: 5px 20px
  border: 1px solid $green1
  &:hover
    background: $green2
    border: 1px solid $green2
  &:disabled
    background: $grey2
    border: 1px solid $grey2
.orange-button
  background: $orange1
  color: $white
  font-size: $f6
  padding: 5px 20px
  border: 1px solid $orange1
  &:hover
    background: $orange2
    border: 1px solid $orange2
  &:disabled
    background: $grey2
    border: 1px solid $grey2

.grey-button
  background: $black
  color: $white
  font-size: $f6
  padding: 5px 20px
  border: 1px solid $black
  &:hover
    background: $black
    border: 1px solid $black
  &:disabled
    background: $grey2
    border: 1px solid $grey2

.red-button
  background: $red1
  color: $white
  font-size: $f6
  padding: 5px 20px
  font-weight: bold
  border: 1px solid $black
  &:hover
    background: $red2
.blue-button
  background: $blue1
  color: $white
  font-size: $f6
  padding: 5px 20px
  border: 1px solid $blue1
  &:hover
    background: $blue2
    border: 1px solid $blue2
  &:disabled
    background: $grey2
    border: 1px solid $grey2

@media (min-width: 768px)
  .body-container
    width: 750px

@media (min-width: 992px)
  .body-container
    width: 970px

@media (min-width: 1200px)
  .body-container
    width: 1170px

.body-container
  margin: 20px auto

.page-title
  font-size: $f1
  font-weight: bold

a
  color: $blue1
  text-decoration: underline